<template>
  <div class="home">
    <MyLinks/>
  </div>



</template>

<script>
// @ is an alias to /src
import MyLinks from '@/components/MyLinks.vue'

export default {
  name: 'Home',
  components: {
    MyLinks
  }
}
</script>

